import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, NgModule } from '@angular/core';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzFormControlStatusType } from 'ng-zorro-antd/form/form-item.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ValueAccessorComponent } from './value-accessor.componet';

@Component({
  selector: 'app-form-input',
  template: `
    <nz-form-item>
      <nz-form-label
        *ngIf="displayLabel"
        class="label"
        [nzNoColon]="true"
      >
        {{ label }}
        <div
          *ngIf="note"
          class="note"
        >
          {{ note }}
        </div>
      </nz-form-label>
      <nz-form-control
        [nzErrorTip]="(errorMessage$ | async) ?? ''"
        [nzValidateStatus]="(validationStatus$ | async) ?? ''"
      >
        <ng-content></ng-content>
      </nz-form-control>
    </nz-form-item>
  `
})
export class FormInputComponent<T> extends ValueAccessorComponent<T> implements AfterViewInit /*, AfterContentInit*/ {
  @Input() public label?: string;
  @Input() public note?: string;
  @Input() public displayLabel = true;
  @Input() public placeholder = '';
  @Input() public autoTips?: Record<string, any>;

  public validationStatus$?: Observable<NzFormControlStatusType>;
  public errorMessage$ = new BehaviorSubject<string>('');

  public ngAfterViewInit() {
    super.ngAfterViewInit();
    this.validationStatus$ = this.formControl?.statusChanges.pipe(
      map((status) => {
        switch (status) {
          case 'INVALID':
            this.setFormError();
            return 'error';
          default:
            return 'success';
        }
      })
    );
  }

  private setFormError() {
    const errors = this.formControl?.errors ?? {};
    const formErrors = Object.entries(errors)
      .filter(([, value]) => !!value)
      .map(([key]) => key)
      .map((key) => this.autoTips?.[key] ?? key);
    this.errorMessage$.next(formErrors[0]);
  }
}

@NgModule({
  declarations: [FormInputComponent],
  exports: [FormInputComponent],
  imports: [NzFormModule, CommonModule]
})
export class FormInputModule {}
