import { AfterViewInit, Directive, forwardRef, InjectFlags, Injector, Provider, Type } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';

export const customValueAccessorFactory = (component: Type<any>): Provider => ({
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => component),
  multi: true
});

@Directive()
export class ValueAccessorComponent<T = any> implements ControlValueAccessor, AfterViewInit {
  public value!: T;
  public disabled?: boolean;
  // public formControl?: AbstractControl | null;
  protected onChange?: (_: any) => void;

  protected onTouch?: () => void;
  protected ngControl?: NgControl;

  constructor(protected inj: Injector) {}

  public get formControl(): AbstractControl | null | undefined {
    return this.ngControl?.control;
  }

  public ngAfterViewInit(): void {
    this.ngControl = this.inj?.get?.(NgControl, undefined, InjectFlags.Optional);
    // this.formControl = this.ngControl?.control;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public writeValue(value: T): void {
    this.onWriteValue(value);
  }

  public setDisabledState(isDisabled: boolean): void {
    this.onDisabled(isDisabled);
  }

  public change(value?: T) {
    this.onChange?.(value);
  }

  protected onWriteValue(obj: T) {
    this.value = obj;
  }

  protected onDisabled(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
